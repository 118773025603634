import { TableContainer, Table, Thead, Tr, Th, Tbody, Td, Tfoot, Box, InputGroup, InputRightElement, Badge, Center, Button, Select, InputLeftElement, Input, Checkbox, Stack, Alert, useDisclosure } from "@chakra-ui/react";
import { SchoolOtherSettingsHelper } from "../../dal/school_other_settings_helper";
import { useEffect, useState } from "react";
import { SchoolAbsenceType } from "../../dal/value_object";
import { DSAAbsenceHelper } from "../../dal/dsa_absence_helper";
import dayjs from 'dayjs';
import { useUserContext } from "../../components/auth/user-validation";
import { NotifyBoxModal } from "../../components/Modals/NotifyBoxModal";
import { AbsenceTypeHelper } from "../../dal/absence_type_helper";
import { useSchoolContext } from "../../dal/SchoolContext";

interface AbsentTypeConfig { // 假別設定清單(顯示用)
  absence_type: string;
  semester: boolean[];
}

export const AbsentTypePage = () => {

  const [absenceType, setAbsenceType] = useState<SchoolAbsenceType[]>([]); // 是否顯示載入中的視窗
  const [absentTypeConfig, setAbsentTypeConfig] = useState<AbsentTypeConfig[]>([]); // 假別設定清單

  const { userInfo } = useUserContext(); // 取得登入者身份資料

  const { selectedSchool } = useSchoolContext(); // 學校資訊

  const { isOpen: isNotifyOpen, onOpen: onNotifyOpen, onClose: onNotifyClose } = useDisclosure(); //提示訊息視窗控制
  const [notifyMessage, setNotifyMessage] = useState(''); //提示訊息內容

  const [refresh, setRefresh] = useState(false); //重新載入資料用


  /** 載入請假類別資料 */
  const loadData = async () => {    
    const currDsns = selectedSchool?.dsns || ''; // Set currDsns to an empty string if it is undefined
    const _absenceType: SchoolAbsenceType[] = await AbsenceTypeHelper.getSchoolAbsenceType(currDsns);
    setAbsenceType(_absenceType);
    const dsaAbsentType = await DSAAbsenceHelper.getAbsenceTypesByDSNS(currDsns); //校務系統中定義的缺曠類別清單
    /** 依據校務節次清單建立設定清單 */
    if(dsaAbsentType !== undefined)
    {
      let tempAbsentType: AbsentTypeConfig[] = [];
      dsaAbsentType.forEach((item: any) => {
        const tempType: AbsentTypeConfig = {
          absence_type: item.AbsenceType,
          semester: [false, false, false, false],
        };
        //if (userInfo?.semester !== undefined) {
          //tempType.semester[userInfo?.semester - 1] = true;
        //}
        tempAbsentType.push(tempType);
      });
      // 與現有設定比對，有設定的節次打勾
      if (_absenceType.length > 0) {
        _absenceType.forEach((item: any) => {
          item.absence_types?.forEach((absence: any) => {
            const absentIdx = tempAbsentType.findIndex((ele) => ele.absence_type === absence);
            if ((absentIdx !== -1)) {
              tempAbsentType[absentIdx].semester[item.semester - 1] = true;
            }
          });
        })
      };
      setAbsentTypeConfig(tempAbsentType);      
    }
    else
    {
      setAbsentTypeConfig([]);
    }

  }   

  //處理缺曠類別變更 (checkbox)
  const handleAbsTypeChange = (index:number, semester:number) => {
    return (e:any) => {
      const newAbsentType = [...absentTypeConfig];
      newAbsentType[index].semester[semester] = e.target.checked;
      setAbsentTypeConfig(newAbsentType);
    }
  }

  // 儲存設定
  const handleSave = async () => {
    const promises: Promise<any>[] = []; // Array to hold all promises

    [1, 2, 3, 4].forEach((sems) => {
      const filterAbsentTypes = absentTypeConfig.filter((item) => item.semester[sems - 1]);
      if (filterAbsentTypes.length > 0) {
        let absenceArray: string[] = [];
        filterAbsentTypes.forEach((item) => absenceArray.push(item.absence_type));
        if(absenceType.find(item=>item.semester===sems))
        {
          promises.push(AbsenceTypeHelper.updateSchoolAbsenceType(sems, absenceArray));
        }
        else
        {
          promises.push(AbsenceTypeHelper.addSchoolAbsenceType(sems, absenceArray));
        }
      }
      else
      {
        if(absenceType.find(item=>item.semester===sems))
        {
          promises.push(AbsenceTypeHelper.deleteSchoolAbsenceType(sems));
        }
      }
    });
    await Promise.all(promises); // Wait for all promises to resolve
    setNotifyMessage('請假類別已更新');
    onNotifyOpen();
    setRefresh(true);    
  }

  useEffect(() => {
    if (refresh) {
      loadData();
      setRefresh(false);
    }
  }, [refresh]);
  
  useEffect(() => {
    loadData();
  }, []);

  const checkPermission = () => {
    /* if(userInfo?.isSystemAdmin)
     {
       if((selectedSchool?.dsns===userInfo?.schoolDsns) && userInfo?.isAdmin)
       {
         return true;
       }
       else
       {
         return false;
       }
     }
     else
     {     
       return false;
     }*/
     if((selectedSchool?.dsns===userInfo?.schoolDsns) && userInfo?.isAdmin)
       {
         return true;
       }
       else
       {
         return false;
       }
 
   }
   
  useEffect(() => {
    loadData();    
  },[selectedSchool]);

  return (
    <>
      <Box borderWidth='1px' borderRadius='lg' p="6" h='87vh' overflow='hidden'>
        <div className="h-full overflow-y-auto">
          {/*<div className="text-xl">假別資料來源</div>
          
          <Stack spacing={5} direction='row' m={4} mb={6}>
            <Checkbox colorScheme='cyan'>同步自1Campus 校務系統</Checkbox>
            <Checkbox colorScheme='cyan'>自行新增</Checkbox>
          </Stack>*/}

          <div className="text-xl">學生可請假別設定</div>

          <TableContainer>
            <Table size='lg' variant='striped' w={850} className="timeTb">
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>缺曠名稱</Th>
                  <Th>上學期</Th>
            {/*      <Th>寒輔</Th> */}
                  <Th>下學期</Th>
            {/*      <Th>暑輔</Th> */}
                </Tr>
              </Thead>
              <Tbody>
                {
                  absentTypeConfig.map((absentType, idx) => (
                    <Tr key={idx}>
                      <Td isNumeric>{idx + 1}</Td>
                      <Td>{absentType.absence_type}</Td>
                      <Td><Checkbox colorScheme='cyan' isChecked={absentType.semester[0]} onChange={handleAbsTypeChange(idx,0)} isDisabled={!checkPermission()}/></Td>
               {/*       <Td><Checkbox colorScheme='cyan' isChecked={absentType.semester[2]} onChange={handleAbsTypeChange(idx,2)} isDisabled={!checkPermission()}/></Td> */}
                      <Td><Checkbox colorScheme='cyan' isChecked={absentType.semester[1]} onChange={handleAbsTypeChange(idx,1)} isDisabled={!checkPermission()}/></Td>
                {/*      <Td><Checkbox colorScheme='cyan' isChecked={absentType.semester[3]} onChange={handleAbsTypeChange(idx,3)} isDisabled={!checkPermission()}/></Td> */}
                    </Tr>
                  ))
                }                    
                {/*
                <Tr>
                  <Td isNumeric>新增</Td>
                  <Td><Input w='110px' placeholder='請輸入' textAlign={['center']} /></Td>
                  <Td><Checkbox colorScheme='cyan' /></Td>
                  <Td><Checkbox colorScheme='cyan' /></Td>
                  <Td><Checkbox colorScheme='cyan' /></Td>
                  <Td><Checkbox colorScheme='cyan' /></Td>
              </Tr> */}
              </Tbody>
            </Table>
          </TableContainer>
          <div className="flex justify-between items-center mt-6">
            <div>最近一次修改時間：{dayjs(absenceType[0]?.last_update).format('YYYY/MM/DD HH:mm:ss')}</div>
            {checkPermission() &&
            <Button className="mr-3" variant='outline' colorScheme='cyan' onClick={() => handleSave()}>儲存</Button>}
          </div>
        </div>
      </Box>
      <NotifyBoxModal isModalOpen={isNotifyOpen} title='資料提示' message={notifyMessage} onModalClose={onNotifyClose}/>
    </>
  )
}
