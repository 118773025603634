import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  ListItem,
  OrderedList,
  Stack,
  Switch,
  Tag,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  CardFooter,
  CardHeader,
  Link as ChakraLink,
} from "@chakra-ui/react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import SignatureCanvas from "react-signature-canvas";
import taiwan_zh from ".././../../taiwan_zh";
import { Link, useNavigate } from "react-router-dom";
import { InfoOutlineIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import { AbsenceTypeHelper } from "../../../dal/absence_type_helper";
import { SchoolPeriodHelper } from "../../../dal/school_period_helper";
import { SchoolDateHelper } from "../../../dal/school_date_helper";
import { useUserContext } from "../../../components/auth/user-validation";
import LeaveDatePeriod from "./LeaveDatePeriod";
import dayjs from "dayjs";
import { DialogBox } from "../../../components/DialogBox";
import { SchoolOtherSettingsHelper } from "../../../dal/school_other_settings_helper";
import { SchoolDate, SchoolOtherSettings } from "../../../dal/value_object";
import { FileStorageHelper } from "../../../dal/file_storage_helper";
import { DatePickerIcon } from "../../../components/DatePickerIcon";
import { LeaveApplicationHelper } from "../../../dal/leave_application_helper";
import { LoadingDialog } from "../../../components/LoadingDialog";
import InputIcon from "react-multi-date-picker/components/input_icon";
import { PdfZoomModal } from "../../../components/Modals/PdfZoomModal";
import { useTranslation } from "react-i18next";

/** 預先請假主畫面 */
export const InAdvance = () => {
  const { userInfo } = useUserContext();
  const navigate = useNavigate();
  const cancelRef = React.useRef(null);
  const { onClose } = useDisclosure();
  const { t, i18n } = useTranslation("translation");
  const fileInputRef = useRef(null) //使用者選擇檔案的input ref


  // ====== 學校設定值 =====
  const [absenceTypes, setAbsenceTypes] = useState<string[]>([]); // 學校在本學期允許請假的假別
  const [allowedPeriods, setAllowedPeriods] = useState<string[]>([]); // 學校在本學期允許請假的節次
  const [schoolDates, setSchoolDates] = useState<SchoolDate[]>([]); // 學校在本學期定義的上課日
  const [schoolOtherSettings, setSchoolOtherSettings] =
    useState<SchoolOtherSettings | null>(null); // 學校其他設定
  const [isSettingReady, setIsSettingReady] = useState<boolean>(false); // 學校是否已經完成設定

  // ===== 紀錄使用者操作 =====
  const [isDialogBoxOpened, setIsDialogBoxOpened] = useState<boolean>(false); // 是否開啟提示的對話方塊
  const todayString = dayjs().format('YYYY/MM/DD')
  const [selectedRangeDates, setSelectedRangeDates] = useState<any[]>([ new DateObject()]); // 日期控制項的 controled component 
  const [selectedDates, setSelectedDates] = useState<string[]>([]); // 使用者選擇要請假的日期
  const [selectedOption, setSelectedOption] = useState<string | null>(null); // 使用者選擇要請的假別
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // 暫存使用者選擇的附件
  const [leaveDatePeriods, setLeaveDatePeriods] = useState<{
    [dt: string]: string[];
  }>({}); // 暫存使用者已選擇要請假的日期與節次
  const [absReason, setAbsReason] = useState<string | null>(""); // 暫存請假理由
  const [isInputDataReady, setIsInputDataReady] = useState<boolean>(false); // 請假填寫資料是否已齊備可送出
  const [showLoading, setShowLoading] = useState<boolean>(false); // 是否顯示載入中圖示的視窗
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [schoolOtherSetting, setSchoolOtherSetting] = useState<any>({}); // 學校的其他設定

  const {
    isOpen: isSignOpen,
    onOpen: onSignOpen,
    onClose: onSignClose,
  } = useDisclosure();
  const {
    onOpen: onRuleOpen,
    isOpen: isRuleOpen,
    onClose: onRuleClose,
  } = useDisclosure();

  const [imageURL, setImageURL] = useState<string | null>(null);
  const sigCanvas = useRef<SignatureCanvas | null>(null);
  const clear = () => sigCanvas.current?.clear();
  const save = () => {
    setImageURL(
      sigCanvas.current?.getTrimmedCanvas()?.toDataURL("/assets/img/png") ??
        null
    );
  };

   /** 元件初始化時 */
   useEffect(() => {
    loadData();
    handleDateChange([new DateObject()])
  }, []);

  /** 當這些變數有變動時 */
  useEffect(() => {
    checkData();

  }, [selectedDates, selectedOption, leaveDatePeriods, absReason, imageURL]);

  /** 讀取資料 */
  const loadData = async () => {
    const p1 = AbsenceTypeHelper.getAllowedAbsenceTypes();
    const p2 = SchoolPeriodHelper.getAllowedPeriods();
    const p3 = SchoolDateHelper.getDatesBySemester();
    const p4 = SchoolOtherSettingsHelper.get();

    Promise.all([p1, p2, p3, p4]).then((values) => {
      console.log({ values });
      setAbsenceTypes(values[0]);
      setAllowedPeriods(values[1]);
      setSchoolDates(values[2]);
      setSchoolOtherSettings(values[3]);

      // console.log({ settings: values[3]})
      // 檢查學校需設定資料是否齊全
      let isReady =
        values[0].length > 0 &&
        values[1].length > 0 &&
        values[2].length > 0 &&
        (!!values[3] ? !!values[3].id : false);
      setIsSettingReady(isReady);
      setIsDialogBoxOpened(!isReady);
    });

    const setting = await SchoolOtherSettingsHelper.getSchoolOtherSettings();
    setSchoolOtherSetting(setting);
  };

  const handleAbsenceTypeChange = (value: string) => {
    setSelectedOption(value);
  };
  // 處理日期改變的事件
  const handleDateChange = (e: DateObject[]) => {
    // 如果選了開始和結束日，才進行處理，找出有效上課日。
    const bd = e[0].toString();
    const ed = e.length > 1 ? e[1].toString() : bd;
    console.log({ e, bd, ed });
    // if (e.length > 1) {
    const dates = findValidDates(bd, ed); // 找出有效的日期，可能根據上課日，或是否假日來判斷。
    setSelectedDates(dates); // 紀錄使用者選取的有效日期清單
    console.log({ dates });
    setLeaveDatePeriods({}); // 清空選取的日期與節次
  };

  /** 當 日曆關閉時 */
  const handleDataPickerClosed = (e: any) => {
    console.log("picker closed");
  };

  /** 處理使用者選擇某一天要請假的節次後 */
  const handleDatePeriodChanged = (dt: string, periods: string[]) => {
    console.log({ dt, periods });
    const isRightDate = selectedDates.find((sd) => sd === dt);
    if (isRightDate) {
      const tempLeaveDatePeriod = { ...leaveDatePeriods };
      tempLeaveDatePeriod[dt] = periods;
      setLeaveDatePeriods(tempLeaveDatePeriod);
    }
  };

  //
  const handleAbsReasonChanged = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setAbsReason(e.target.value);
  };

  /**
   * 檢查找出有效的日期
   * 如果學校有定義上課日，則以上課日為主，否則就檢查排除六日
   * */
  const findValidDates = (beginDate: string, endDate: string): string[] => {
    const result: string[] = [];
    let bd = dayjs(beginDate); 
    // 開始日期不能早於今天
    if (bd.format('YYYYMMDD') < dayjs().format('YYYYMMDD')) {
      bd = dayjs();
    }
    const ed = dayjs(endDate);
    let targetD = bd;
    while (targetD <= ed) {
      // console.log({ targetD });
      let isValidDate = false;
      if (schoolDates.length > 0) {
        const td = schoolDates.find(
          (sd) =>
            targetD.format("YYYY/MM/DD") === dayjs(sd.d).format("YYYY/MM/DD")
        );
        isValidDate = !!td;
      } else {
        // 學校沒有定義上課日，就直接排除六日
        isValidDate = targetD.day() !== 0 && targetD.day() !== 6;
      }
      // 如果是有效的上課日，才讓使用者進行請假
      if (isValidDate) {
        result.push(targetD.format("YYYY/MM/DD"));
      }
      targetD = targetD.add(1, "day");
    }
    return result;
  };

  /** 暫存使用者選取的檔案 */
  const handleAttachFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
    }
  };

  /** 點擊上傳檔案外觀按鈕，開啟檔案選擇視窗 */
  const handleUploadFileClick = () => {
    if (fileInputRef.current) {
      (fileInputRef.current as HTMLInputElement).click();
    }
  }
  
  /** 上傳檔案 */
  const uploadFile = async (file: File | null) => {
    if (!file) return;
    try {
      const attInfo = await FileStorageHelper.uploadFile(file);
      console.log(attInfo);
      return attInfo;
    } catch (ex) {
      throw ex ;
    }
  };

  /** 檢查使用者是否已輸入所有必要資訊 */
  const checkData = () => {
    console.log(" checkData() ...");

    let result = true;
    // 1. 是否選取日期
    result = result && selectedDates.length > 0;

    // 2. 是否選取假別
    result = result && !!selectedOption;

    // 3. 是否有日期沒有選節次
    selectedDates.forEach((sd) => {
      result =
        result && !!leaveDatePeriods[sd] && leaveDatePeriods[sd].length > 0;
    });

    // 4. 是否輸入請假原因
    result = result && !!absReason;

    // 5. 檢查是否簽名
    const needParentSign = schoolOtherSettings
      ? schoolOtherSettings.need_parent_approval
      : false;
    if (needParentSign && userInfo?.roleType === "parent" && !imageURL) {
      result = result && false;
      // window.alert("需要簽名");
    }

    setIsInputDataReady(result);

    return result;
  };

  /** 送出申請單 */
  const submitLeaveApplication = async () => {
    console.log("submit application");
    setShowLoading(true);

    let isReady = true ;

    let attInfo ;
    // 確認附件
    try {
      attInfo = await uploadFile(selectedFile);
    } catch (ex: any) {
      console.log({ ex });
      if (ex.code === 413) {
        alert('附件大小請勿超過 5MB.')
      } else {
        alert('上傳附件失敗！');
      }
      isReady = false ;
    }

    // 上傳簽名檔
    let signFileInfo ;

    if (isReady) {   // 如果前一步驟成功，才繼續這個步驟上傳簽名檔。
      let signFile = FileStorageHelper.convertDataURLtoFile(
        imageURL,
        "parent_signature"
      ); 
      // 把 imageUrl 轉成 File 物件
      try {
        signFileInfo = await uploadFile(signFile);
        console.log({ signFileInfo });
      } catch (ex) {
        console.log({ ex });
        isReady = false ;
      }
    }

    if (isReady) {
      // 4. 送出
      const data = {
        absence_type: selectedOption,
        leaveDateAndPeriod: makeLeaveDateAndPeriodPayload(),
        reason: absReason,
        need_parent_signature: schoolOtherSettings?.need_parent_approval,
        attachment_info: attInfo,
        parent_sign_info: signFileInfo,
      };
      // console.log( { data });
      const resp = await LeaveApplicationHelper.send(data);
      
      setShowLoading(false);
      navigate("../history"); 
    } else {
      setShowLoading(false);
    }
  };

  const makeLeaveDateAndPeriodPayload = () => {
    const result = selectedDates.map((sd) => ({
      d: sd,
      periods: leaveDatePeriods[sd],
    }));

    return result;
  };

  return (
    <>
      <div className="max-w-md bg-slate-100 h-screen p-4">
        <div className="flex items-center gap-3">
          <div className="flex-1 text-start">
            <Button
              colorScheme="blue"
              variant="ghost"
              size="md"
              className="!p-0 w-fit"
              onClick={() => navigate(-1)}
              leftIcon={
                <Icon w={6} h={6} color="blue.500" viewBox="0 0 24 24">
                  <g fill="none" fillRule="evenodd">
                    <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                    <path
                      fill="currentColor"
                      d="M8.293 12.707a1 1 0 0 1 0-1.414l5.657-5.657a1 1 0 1 1 1.414 1.414L10.414 12l4.95 4.95a1 1 0 0 1-1.414 1.414z"
                    />
                  </g>
                </Icon>
              }
            >
              {/* <span>返回主頁</span> */}
            </Button>
          </div>
          <div className="text-center text-lg font-semibold grow">
            {t("app.planned_leave")}
          </div>
          <div className="flex-1" />
        </div>

        <div className="flex flex-wrap items-center pt-3 gap-2">
          <div className="flex">
            <span className="">{userInfo?.schoolYear}{i18n.language === "en" ? "-Semester " : "學年度 第" }</span>
            <span className="">{userInfo?.semester}{i18n.language === "en" ? "" : "學期" }</span>
          </div>

          {/*<div onClick={()=>setIsPdfModalOpen(true)} className="cursor-pointer">          
            <InfoOutlineIcon />
              <span className="ms-1 align-middle">請假規定</span>
          </div>*/}
            <ChakraLink href={schoolOtherSetting?.leave_rule+'?__target=InAppBrowser'} isExternal className="cursor-pointer ms-auto" style={{ textDecoration: 'none' }}>
              <InfoOutlineIcon />
              <span className="ms-2 align-middle">{t("app.rules_of_leave")}</span>
            </ChakraLink> 
        </div>

        {
          (schoolDates.length === 0) && (
            <div className="flex justify-between items-center pb-2 pl-2 ">
              <div className=" text-red-500 font-bold">{t("app.no_class_days_set")}</div>
            </div>
          )
        }

        <Card className="h-stud-ad overflow-auto !rounded-2xl mt-4">
          <div className="text-left p-4">
            <OrderedList spacing={3}>
              <ListItem className="font-semibold">{t("app.leave_dates")}</ListItem>
              <div className="!mt-2 pr-4">
                <InputGroup>
                  <DatePicker
                    range
                    minDate={ dayjs().add(-1, 'day').toDate()}
                    locale={taiwan_zh}
                    value={selectedRangeDates}
                    onChange={ handleDateChange}
                    render={<InputIcon/>}
                  />
                  {/* <InputRightElement>
                    <DatePickerIcon />
                  </InputRightElement> */}
                </InputGroup>
              </div>
              <ListItem className="font-semibold">
                {t("app.leave_category_l")}{" "}
                {!selectedOption && (
                  <span className="text-sm text-red-500 ms-2">{t("app.leave_category_not_selected")}</span>
                )}
              </ListItem>
              <div className="!mt-2">
                <Stack align="center" direction="row" className="flex-wrap">
                  {absenceTypes.map((absType) => (
                    <Switch
                      key={absType}
                      size="lg"
                      colorScheme="twitter"
                      content={absType}
                      className="switch-txt"
                      onChange={() => handleAbsenceTypeChange(absType)}
                      isChecked={selectedOption === absType}
                    />
                  ))}
                </Stack>
              </div>
              <ListItem className="font-semibold">
                {t("app.leave_sessions")} ( {selectedDates.length} {t("app.day")} { (selectedDates.length === 0 ) && (<>，<span className="text-sm text-red-500 ms-2">{t("app.selected_dates_no_class_days")}</span> </>) })
              </ListItem>
              {selectedDates.map((sd) => (
                <LeaveDatePeriod
                  key={sd}
                  targetDate={sd}
                  allowPeriods={allowedPeriods}
                  onSelected={handleDatePeriodChanged}
                />
              ))}

              <ListItem className="font-semibold">
                {t("app.leave_reason_l")} {" "}
                {!absReason && (
                  <span className="text-sm text-red-500 ms-2">{t("app.leave_reason_unfilled")}</span>
                )}
              </ListItem>
              
              <div className="pr-4">
                <Textarea
                  placeholder={t("app.enter_leave_reason")}
                  onChange={handleAbsReasonChanged}
                />
              </div>
              <ListItem className="font-semibold">{t("app.upload_attachment")}
              { (userInfo?.roleType === "parent" &&
                  schoolOtherSettings?.need_parent_approval) && (t("app.and_parent_sign"))}
              </ListItem>
              <InputGroup className="!grid grid-cols-1 gap-4 w-auto pr-4 ">
                {/* 選擇檔案的按鈕，為了翻譯做一個外表 */}
                <Input
                  type="text"
                  placeholder="Enter amount"
                  className="w-full z-10 cursor-pointer"
                  isReadOnly={true}
                  onClick={handleUploadFileClick}
                  value={selectedFile?.name || t("app.no_file_selected")}
                />
                <Input
                  type="file"
                  placeholder="Enter amount"
                  className="w-full pt-2 z-10  cursor-pointer hidden"
                  accept=".jpg, .jpeg, .png, .gif, .pdf"
                  onChange={handleAttachFileChange}
                  ref={fileInputRef}
                />
                <InputRightElement className="!right-4">
                  <Icon w={6} h={6} color="gray.500" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M11 16V7.85l-2.6 2.6L7 9l5-5l5 5l-1.4 1.45l-2.6-2.6V16zm-5 4q-.825 0-1.412-.587T4 18v-3h2v3h12v-3h2v3q0 .825-.587 1.413T18 20z"
                    />
                  </Icon>
                </InputRightElement>

                {/* 簽名按鈕 */}
                {userInfo?.roleType === "parent" &&
                  schoolOtherSettings?.need_parent_approval && (
                    <div className="flex flex-wrap gap-3 items-end">
                      {imageURL && (
                        <div className="mx-auto flex-shrink-0">
                          <img
                            src={imageURL}
                            alt="Signature"
                            className="h-28 mx-auto"
                          />
                        </div>
                      )}
                      <Button
                        onClick={onSignOpen}
                        rightIcon={
                          <Icon
                            w={6}
                            h={6}
                            color="gray.500"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M9.75 20.85c1.78-.7 1.39-2.63.49-3.85c-.89-1.25-2.12-2.11-3.36-2.94A9.817 9.817 0 0 1 4.54 12c-.28-.33-.85-.94-.27-1.06c.59-.12 1.61.46 2.13.68c.91.38 1.81.82 2.65 1.34l1.01-1.7C8.5 10.23 6.5 9.32 4.64 9.05c-1.06-.16-2.18.06-2.54 1.21c-.32.99.19 1.99.77 2.77c1.37 1.83 3.5 2.71 5.09 4.29c.34.33.75.72.95 1.18c.21.44.16.47-.31.47c-1.24 0-2.79-.97-3.8-1.61l-1.01 1.7c1.53.94 4.09 2.41 5.96 1.79m11.09-15.6c.22-.22.22-.58 0-.79l-1.3-1.3a.562.562 0 0 0-.78 0l-1.02 1.02l2.08 2.08M11 10.92V13h2.08l6.15-6.15l-2.08-2.08z"
                            />
                          </Icon>
                        }
                        className="w-full"
                      >
                        {imageURL ? (t("app.resign")) : (t("app.start_to_sign"))}
                      </Button>

                    </div>
                  )}
              </InputGroup>
            </OrderedList>
            <Button
              leftIcon={
                <Icon w={6} h={6} color="white" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M7.1 11.35q.35-.7.725-1.35t.825-1.3l-1.4-.275l-2.1 2.1zm12.05-6.875q-1.75.05-3.737 1.025T11.8 8.1q-1.05 1.05-1.875 2.25T8.7 12.6l2.85 2.825q1.05-.4 2.25-1.225t2.25-1.875q1.625-1.625 2.6-3.6T19.675 5q0-.1-.038-.2t-.112-.175q-.075-.075-.175-.112t-.2-.038m-5.5 6q-.575-.575-.575-1.412t.575-1.413q.575-.575 1.425-.575t1.425.575q.575.575.575 1.413t-.575 1.412q-.575.575-1.425.575t-1.425-.575m-.85 6.55L13.625 19l2.1-2.1l-.275-1.4q-.65.45-1.3.813t-1.35.712m8.775-13.35q.2 2.75-.9 5.363T17.2 14.025l.5 2.475q.1.5-.05.975t-.5.825L14 21.45q-.375.375-.9.288t-.725-.588l-1.525-3.575L6.575 13.3L3 11.775q-.5-.2-.6-.725t.275-.9L5.825 7q.35-.35.837-.5t.988-.05l2.475.5q2.375-2.375 4.988-3.475t5.362-.9q.2.025.4.113t.35.237q.15.15.238.35t.112.4m-17.65 12.3q.875-.875 2.138-.887t2.137.862q.875.875.863 2.138t-.888 2.137q-1.2 1.2-2.838 1.425t-3.287.45l.45-3.287q.225-1.637 1.425-2.838m1.425 1.4q-.425.425-.587 1.025T4.5 19.625q.625-.1 1.225-.25T6.75 18.8q.3-.3.325-.725T6.8 17.35q-.3-.3-.725-.288t-.725.313"
                  />
                </Icon>
              }
              colorScheme="twitter"
              className="w-full my-8 disabled"
              onClick={submitLeaveApplication}
              isDisabled={ (schoolDates.length === 0) || !isInputDataReady}
            >
              {/* 如果是學生，且學校規定要家長簽名，則出現「傳送給家長簽名」，否則出現「送出假單」 */}
              { ((userInfo?.roleType === 'student') && (schoolOtherSettings?.need_parent_approval)) ? (i18n.language === "en" ? "Send to parent to sign" : "傳送給家長簽名" ) : (i18n.language === "en" ? "Send Leave Application" : "送出假單" )}
            </Button>
          </div>
          <CardFooter className="!p-2" />
        </Card>
      </div>

      {/* 顯示請假規定 
      <Modal
        size="xl"
        closeOnOverlayClick={false}
        isOpen={isRuleOpen}
        onClose={onRuleClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader />
          <ModalCloseButton />

          <ModalBody className="!px-3">
            <object
              data="/assets/pdf/rule.pdf"
              type="application/pdf"
              title="請假規定"
              width="100%"
              className="min-h-[600px]"
            />
          
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal> /*}

      {/* 顯示家長簽名的視窗 */}
      <Modal
        closeOnOverlayClick={false}
        isOpen={isSignOpen}
        onClose={onSignClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="!font-normal">
            {t("app.parent_sing_info")}
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody pb={6}>
            <div className="h-52 w-full border border-gray-200">
              <SignatureCanvas
                penColor="blue"
                ref={sigCanvas}
                canvasProps={{ className: "sigCanvas w-full h-52" }}
              />
            </div>
          </ModalBody>

          <ModalFooter className="gap-4">
            <Button
              colorScheme="twitter"
              variant="outline"
              className="mr-auto w-full"
              onClick={clear}
            >
              {t("app.clear")}
            </Button>
            <Button
              colorScheme="twitter"
              className="w-full"
              onClick={() => {
                save();
                onSignClose();
              }}
            >
              {t("app.confirm")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* 提醒使用者學校資料尚未設定完全的視窗 */}
      <DialogBox
        isOpen={isDialogBoxOpened}
        title="錯誤"
        msg="當學期未完成請假設定，目前無法請假！請系統管理員檢查上課日、節次及請假類別設定。"
        closeButtonText="取消"
        showCancelButton={false}
        okButtonText="返回"
        onOK={() => navigate(-1)}
      />
      <PdfZoomModal
        isModalOpen={isPdfModalOpen}
        onModalClose={()=>setIsPdfModalOpen(false)}
        pdfUrl="../assets/pdf/rule.pdf"
        title="請假單"
      />
      {/* 顯示載入中的視窗 */}
      <LoadingDialog isOpen={showLoading} />
    </>
  );
};
