import React, { useState, useEffect } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

interface ImagePreviewProps {
  src: string;
  onClose: () => void;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ src, onClose }) => {
  const [initialScale, setInitialScale] = useState(1);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      // Dynamically calculate initialScale based on image and container dimensions
      const containerWidth = window.innerWidth * 0.95;
      const containerHeight = window.innerHeight * 0.95;
      const scaleX = containerWidth / img.width;
      const scaleY = containerHeight / img.height;
      const scale = Math.max(scaleX, scaleY);
      setInitialScale(scale);
    };
    img.src = src;
  }, [src]);

  if (!src) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center" onClick={onClose}>
      <TransformWrapper initialScale={initialScale} centerOnInit={true} limitToBounds={false}>
        <TransformComponent> 
          <img src={src} alt="Preview" style={{ maxWidth: '100%', maxHeight: '100%', background:'white' }} />
        </TransformComponent>
      </TransformWrapper>
      <div className="absolute top-4 right-4 text-white cursor-pointer z-10" onClick={onClose}>Close</div>
    </div>
  );
};

export default ImagePreview;
