import { AttachmentInfo, SchoolDate, UploadToken } from "./value_object";

/** 
 * 處理檔案上傳  
 * 參考：https://uploadcare.com/blog/how-to-upload-file-in-react/
 * 參考：https://github.com/ischoolinc/storage.1campus.net/blob/main/demo/README.md
 * */
export class FileStorageHelper {

    /** 上傳檔案 */
    static async uploadFile(file: File | null): Promise<AttachmentInfo | null> {
        if (!file) return null;
        const formData = new FormData();
        formData.append('file', file);

        const uploadToken: UploadToken = await FileStorageHelper.getUploadToken();

        const uploadUrl = `https://storage.1campus.net/file?upload_token=${uploadToken.token}`;

        const response = await fetch(uploadUrl, {
            method: 'POST',
            body: formData,
        });
        
        // fetch api 的錯誤不能使用 try ... catch 攔截，必須使用 response.ok 判斷。
        // 請參考：https://dev.to/dionarodrigues/fetch-api-do-you-really-know-how-to-handle-errors-2gj0
        if (response.ok) {
            return (await response.json())[0]
        } else {
            const ex = await response.json();
            ex.code = response.status ;
            // console.log( { ex })
            throw ex ;
        }
            
    }

    /** 以ID取得檔案的metadata */
    static async getFileMetadata(url: string): Promise<any> {
        const resp = await fetch(url+'/metadata');
        return await resp.json();
    }

    /** 取得上傳檔案用的 upload token */
    static async getUploadToken(): Promise<UploadToken> {
        const resp = await fetch('/service/file/upload_token');
        return await resp.json();
    }

    /** 把 image dataurl 轉換成 File 物件 */
    static convertDataURLtoFile(dataurl: string | null, filename: string) {
        // data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/uk+td.....=
        if (!dataurl) { return null; }

        const arr = dataurl.split(",");
        // 找 mime.
        const temp = arr[0];
        const mime_temp = temp.split(':')[1];
        let mime = mime_temp.split(';')[0];

        const bstr = atob(arr[arr.length - 1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], `${filename}.${mime[0]}`, { type: mime });
    }
}