import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,Textarea} from "@chakra-ui/react"
interface props {
    isModalOpen:boolean;
    title:string;
    message:string;
    onModalClose:()=>void;    
}

export const NotifyBoxModal=({isModalOpen,title,message,onModalClose}:props)=>{
    return(
        <Modal
            closeOnOverlayClick={false}
            isOpen={isModalOpen}
            onClose={onModalClose}
            size='sm'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader className='!font-normal'>{title}</ModalHeader>
                <ModalCloseButton />

                <ModalBody pb={6}>
                    {message.split('\n').map((line, index) => (
                    <div key={index}>{line}</div>
            ))}
                </ModalBody>

                <ModalFooter className='gap-4'>
                    <Button colorScheme='orange' className="w-full" onClick={() => { onModalClose()}}>OK</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}