import { useEffect, useRef, useState, Component } from 'react';
import { Box, Button, Icon, Input, InputGroup, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, StackDivider, Tag, useDisclosure } from "@chakra-ui/react";
import SignatureCanvas from 'react-signature-canvas';
import { Util } from '../../dal/util';
import { FileStorageHelper } from '../../dal/file_storage_helper';
import { LeaveApplicationHelper } from '../../dal/leave_application_helper';
import { LoadingDialog } from '../../components/LoadingDialog';
import { Link, useNavigate } from "react-router-dom";
import { useUserContext } from '../../components/auth/user-validation';
import config  from '../../config';
import dayjs from 'dayjs';
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
import React from 'react';
import { is } from 'date-fns/locale';
import ImagePreview from '../../components/ImagePreview';
import { useTranslation } from "react-i18next";


interface LeaveDetailProps {
  leaveAppRec: any;
  onLeaveClose: () => void;
}

export const LeaveDetail = ({ leaveAppRec,onLeaveClose}: LeaveDetailProps) => {
  const { userInfo } = useUserContext();
  const { isOpen: isParentSignOpen, onOpen: onParentSignOpen, onClose: onParentSignClose } = useDisclosure();

  const [imageURL, setImageURL] = useState<string | null>(null);
  const sigCanvas = useRef<SignatureCanvas | null>(null);
  const [currState, setCurrState] = useState<any>(
    {      
      progressText: "",
      progressTextEn: "",
      progressTextCSS: "",
      statusText: "",
      statusTextEn: "",
      statusTextCSS: "",
      status: "",
    }
  );
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // 暫存使用者選擇的附件
  const fileInputRef = useRef(null) //使用者選擇檔案的input ref
  const [showLoading, setShowLoading] = useState<boolean>(false); // 是否顯示載入中的視窗

  const [selectedImage, setSelectedImage] = useState<string | null>(null); // 顯示放大圖片
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const { t, i18n } = useTranslation("translation");

  const weekDays=['日','一','二','三','四','五','六'];
  const weekDaysEn=['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const handleImageClick = (src: string) => {
    setSelectedImage(src);
    setIsPreviewOpen(true)
  };  
  const hideImagePreview = () => {
    setSelectedImage(null);
  };

  /** 檢查是否允許上傳附件 */
  const enAttchment = () => {
    if (leaveAppRec.status === 'parent_sign')  {
      return true;
    }    
    else {
      return false;
    }
  }

  /** 檢查是否允許簽名 */
  const enSign = () => {
    if (leaveAppRec.status === 'parent_sign' && userInfo?.roleType === "parent" && leaveAppRec.parent_sign_info === null)  {
      return true;
    }    
    else {
      return false;
    }
  }

  const clear = () => sigCanvas.current?.clear();
  const save = () => {
    setImageURL(sigCanvas.current?.getTrimmedCanvas()?.toDataURL("/assets/img/png") ?? null);
    // console.log(sigCanvas.current?.toDataURL("/assets/img/png", 1));
  };
  const navigate = useNavigate();

  /** 暫存使用者選取的檔案 */
  const handleAttachFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const reSortLeaveArray = () => {
    let leaveArray = leaveAppRec.leaveDateAndPeriod || [];
    leaveArray.sort((a: any, b: any) => {
      return new Date(a.d).getTime() - new Date(b.d).getTime();
    });
    return leaveArray;
  }

  const date = new Date(leaveAppRec.apply_time);
  const apply_time = date.toLocaleString('zh-Hans', { hour12: false, }).replaceAll("/", "-");

  const leaveArray = reSortLeaveArray(); // 請假日期陣列
  let attachmentInfo=leaveAppRec.attachment_info; // 附件資訊
  let parentSignInfo=leaveAppRec.parent_sign_info; // 家長簽名資訊

  const handleReSubmit = async () => { //變更假單資料
    setShowLoading(true);
    
      //1.上傳附件檔產生附件資訊
    let isReady = true ;

    let attachmentInfo ;
    try {
      attachmentInfo = await Util.uploadFile(selectedFile);
    }catch (ex: any) {
      console.log({ ex });
      if (ex.code === 413) {
        alert('附件大小請勿超過 5MB.')
      } else {
        alert('上傳附件失敗！');
      }
      isReady = false ;
    }

    // 2.上傳簽名檔產生簽名檔資訊
    const signFile = FileStorageHelper.convertDataURLtoFile(
      imageURL,
      "parent_signature"
    ); // 把 imageUrl 轉成 File 物件
    const parentSignInfo = await Util.uploadFile(signFile);
    
    let isParentSign = false;
    if(leaveAppRec.need_parent_signature && parentSignInfo)
    {
      isParentSign = true;
    }
    
    // 3.更新假單資料及處理家長簽核(附件資訊、簽名檔資訊)
    if(isReady) {
    const result = await LeaveApplicationHelper.updateAttachParentSign(leaveAppRec.id, attachmentInfo, parentSignInfo, isParentSign);    

    console.log(result);
    setShowLoading(false);
    onLeaveClose();
    }
    else {
      setShowLoading(false);
    }
  }

  const handleCancel = async () => {
    setShowLoading(true);
    const result = await LeaveApplicationHelper.cancel(leaveAppRec.id);
    console.log(result);
    setShowLoading(false);
    onLeaveClose();
  }

  const needUpload = () => { //檢查是否有附件
    if (userInfo?.roleType === "parent" && leaveAppRec.need_parent_signature && imageURL || selectedFile) {
      return true;
    }    
    else {
      return false;
    }
  }

  const needCancel = () => { //檢查是否可以抽單
    if ((currState.progressText==='退回') || (currState.progressText==='已完成')) {
      return false;
    }    
    else {
      return true;
    }
  }

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
  };

  /** 點擊上傳檔案外觀按鈕，開啟檔案選擇視窗 */
  const handleUploadFileClick = () => {
    if (fileInputRef.current) {
      (fileInputRef.current as HTMLInputElement).click();
    }
  }

  useEffect(() => {
    setCurrState(Util.calculateProgressText(leaveAppRec));   
  }, []);
  return (
    <>
      <div className='text-left'>
        <div className="flex items-center gap-2">
          {/* }
          <Tag size='md' className='w-[58px] justify-center me-2 !text-white bg-ing' >申請中</Tag>
          <Tag size='md' className="w-[58px] justify-center me-2 !text-white bg-return">退回</Tag>
          <Tag size='md' className="w-[58px] justify-center me-2 !text-white bg-ed">已完成</Tag> */}
          <Tag size='md' className={'justify-center !text-black whitespace-nowrap flex-shrink-0 '+currState.progressTextCSS} >{i18n.language === "en"
                  ? currState.progressTextEn : currState.progressText}</Tag>
          <div className="text-sm">
            <div>{t("app.pending_time")}：{dayjs(apply_time).format('YYYY/MM/DD HH:mm:ss')}</div>
            <div>{t("app.serial_no")}：{leaveAppRec?.application_no}</div>
          </div>
        </div>
        {(currState.progressText==='申請中') && 
          <div className="text-xl font-semibold mt-3 ">
          {(i18n.language === "en"
            ? currState.progressTextEn
            : currState.progressText) + "："}
          <span className={currState.statusTextCSS}>
            {i18n.language === "en" ? currState.statusTextEn : currState.statusText}
          </span>
        </div>
        }
        <Stack divider={<StackDivider />} spacing='3' className="px-3">
          <Box />
          <Box>
            {
              leaveArray.map((item: any, index: number) => {
                return (
                  <>
                    <div className='text-sm font-semibold'>{dayjs(item.d).format('YYYY/MM/DD')}({i18n.language === "en" ? weekDaysEn[dayjs(item.d).day()] : weekDays[dayjs(item.d).day()]})</div>
                    <div>{item.periods.join(",")}</div>
                  </>
                )
              })
            }
          </Box>
          <Box>
            <div className='text-sm font-semibold'>{t("app.leave_category")}</div>
            <div>{leaveAppRec.absence_type}</div>
          </Box>
          <Box>
            <div className='text-sm font-semibold'>{t("app.leave_reason")}</div>
            <div>{leaveAppRec.reason}</div>
          </Box>
          <Box>
            <div className='text-sm font-semibold mb-4'>
              <span className='whitespace-nowrap'>{t("app.attachment")}：</span>
              <span className=''>
                {(attachmentInfo)? 
                  <>
                    {attachmentInfo.filename}
                    <img src={config.storageURL+'/'+attachmentInfo?.fileid} alt="Signature" className='h-12 mx-auto imgs' 
                      onClick={() => handleImageClick(config.storageURL+'/'+attachmentInfo?.fileid)}
                    />
                  </> 
                  : t("app.no_attachment")}
              </span>
            </div>
         {/*  {selectedImage && (
              <div className="block imgPreview" onClick={hideImagePreview}>
                <img src={selectedImage} alt="Preview" style={{ width: '200px', height: '150px', objectFit: 'contain' }}/>
              </div>
         )}   */}
         { (enAttchment()) &&
           <div className="flex justify-between items-end">
            {/*}   <div className='whitespace-nowrap pr-6'>{(attachmentInfo)? attachmentInfo.filename : '無附件'}</div>*/}
              <InputGroup className="" >
                {/* 選擇檔案的按鈕，為了翻譯做一個外表 */}
                <Input
                  type="text"
                  placeholder="Enter amount"
                  className="w-full z-10 cursor-pointer"
                  isReadOnly={true}
                  onClick={handleUploadFileClick}
                  value={selectedFile?.name || t("app.no_file_selected")}
                />
                <Input className='pt-1 z-10 hidden' type="file" accept="image/*" placeholder='Enter amount' onChange={handleAttachFileChange} ref={fileInputRef}/>
                  <InputRightElement >
                    <Icon w={6} h={6} color='gray.500' viewBox='0 0 24 24'>
                      <path fill="currentColor" d="M11 16V7.85l-2.6 2.6L7 9l5-5l5 5l-1.4 1.45l-2.6-2.6V16zm-5 4q-.825 0-1.412-.587T4 18v-3h2v3h12v-3h2v3q0 .825-.587 1.413T18 20z" />
                    </Icon>
                  </InputRightElement>
              </InputGroup>
            </div>
          }
          </Box>
          <Box>
            <>
              <div className='text-sm font-semibold'>{t("app.parent_sign")}</div>
              <div className="flex justify-between items-end">
                {/*  <div className='whitespace-nowrap pr-2'>
                  { imageURL || parentSignInfo ?
                    <Button onClick={onParentSignOpen} rightIcon={
                      <Icon w={6} h={6} color='gray.500' viewBox='0 0 24 24'>
                        <path fill="currentColor" d="M9.75 20.85c1.78-.7 1.39-2.63.49-3.85c-.89-1.25-2.12-2.11-3.36-2.94A9.817 9.817 0 0 1 4.54 12c-.28-.33-.85-.94-.27-1.06c.59-.12 1.61.46 2.13.68c.91.38 1.81.82 2.65 1.34l1.01-1.7C8.5 10.23 6.5 9.32 4.64 9.05c-1.06-.16-2.18.06-2.54 1.21c-.32.99.19 1.99.77 2.77c1.37 1.83 3.5 2.71 5.09 4.29c.34.33.75.72.95 1.18c.21.44.16.47-.31.47c-1.24 0-2.79-.97-3.8-1.61l-1.01 1.7c1.53.94 4.09 2.41 5.96 1.79m11.09-15.6c.22-.22.22-.58 0-.79l-1.3-1.3a.562.562 0 0 0-.78 0l-1.02 1.02l2.08 2.08M11 10.92V13h2.08l6.15-6.15l-2.08-2.08z" />
                      </Icon>}
                      className='w-full'>重新簽名
                    </Button> :
                    "尚未簽名"
                  } 
                </div> */}
                  { imageURL ?
                    <div className={`w-full ${i18n.language === "en" ? "signbgEn" :"signbg"}`}>
                      <img src={imageURL} alt="Signature" className='h-24 mx-auto imgs' onClick={() => handleImageClick(imageURL)}/>
                    </div>
                    :
                    parentSignInfo ?
                    <div className={`w-full ${i18n.language === "en" ? "signbgEn" :"signbg"}`}><img src={config.storageURL +'/' +parentSignInfo?.fileid} alt="Signature" className='h-20 mx-auto imgs' onClick={() => handleImageClick(config.storageURL +'/' +parentSignInfo?.fileid)}/></div> :
                    (enSign()) &&
                    <Button isDisabled={(leaveAppRec?.status !== 'parent_sign')} onClick={onParentSignOpen} rightIcon={
                      <Icon w={6} h={6} color='gray.500' viewBox='0 0 24 24'>
                        <path fill="currentColor" d="M9.75 20.85c1.78-.7 1.39-2.63.49-3.85c-.89-1.25-2.12-2.11-3.36-2.94A9.817 9.817 0 0 1 4.54 12c-.28-.33-.85-.94-.27-1.06c.59-.12 1.61.46 2.13.68c.91.38 1.81.82 2.65 1.34l1.01-1.7C8.5 10.23 6.5 9.32 4.64 9.05c-1.06-.16-2.18.06-2.54 1.21c-.32.99.19 1.99.77 2.77c1.37 1.83 3.5 2.71 5.09 4.29c.34.33.75.72.95 1.18c.21.44.16.47-.31.47c-1.24 0-2.79-.97-3.8-1.61l-1.01 1.7c1.53.94 4.09 2.41 5.96 1.79m11.09-15.6c.22-.22.22-.58 0-.79l-1.3-1.3a.562.562 0 0 0-.78 0l-1.02 1.02l2.08 2.08M11 10.92V13h2.08l6.15-6.15l-2.08-2.08z" />
                      </Icon>}
                      className='w-full' >{t("app.start_to_sign")}
                    </Button>
                  }
                  {/*selectedImage && (
                    <div className="block imgPreview" onClick={hideImagePreview} >
                      <img src={selectedImage} alt="Preview" className='signbg'/>
                    </div>
                  )*/}
                  {selectedImage && isPreviewOpen &&
                    <ImagePreview src={selectedImage} onClose={handleClosePreview} />
                  }
                    {/*<ImagePreview src={selectedImage} onClose={hideImagePreview} />*/}

              </div>
            </>

          </Box>

          <Box>
            <div className='text-sm font-semibold'>{t("app.application_reply")}</div>
            { leaveAppRec?.approveHistory.map( (aph: any) => (
                  ((aph.status==='rejected'))? 
                  <>
                    <div>{i18n.language === "en" ? Util.convertRoleToTextEn(aph.stage_name) : aph.stage_name}：<span className="text-xl text-red-500 font-semibold">{i18n.language === "en" ? Util.convertApproveToTextEn(aph.status) : Util.convertApproveToText(aph.status)}</span><span> {aph.comment}</span></div>                  
                    <div className="text-sm mb-2">{dayjs(aph.approve_time).format('YYYY/MM/DD HH:mm:ss')}</div>
                  </>
                  :
                  <>
                    <div>{i18n.language === "en" ? Util.convertRoleToTextEn(aph.stage_name) : aph.stage_name}：{i18n.language === "en" ? Util.convertApproveToTextEn(aph.status) : Util.convertApproveToText(aph.status)}</div>
                    {(aph.status==='approved') && <div className="text-sm mb-2">{dayjs(aph.approve_time).format('YYYY/MM/DD HH:mm:ss')}</div>}
                  </>
                ))}
          </Box>

        </Stack>
      </div>
      <Button colorScheme='twitter' className='mt-6 w-full' onClick={()=>handleReSubmit()} isDisabled={!needUpload()} leftIcon={<Icon w={6} h={6} color='white' viewBox='0 0 24 24'>
          <path fill="currentColor" d="M7.1 11.35q.35-.7.725-1.35t.825-1.3l-1.4-.275l-2.1 2.1zm12.05-6.875q-1.75.05-3.737 1.025T11.8 8.1q-1.05 1.05-1.875 2.25T8.7 12.6l2.85 2.825q1.05-.4 2.25-1.225t2.25-1.875q1.625-1.625 2.6-3.6T19.675 5q0-.1-.038-.2t-.112-.175q-.075-.075-.175-.112t-.2-.038m-5.5 6q-.575-.575-.575-1.412t.575-1.413q.575-.575 1.425-.575t1.425.575q.575.575.575 1.413t-.575 1.412q-.575.575-1.425.575t-1.425-.575m-.85 6.55L13.625 19l2.1-2.1l-.275-1.4q-.65.45-1.3.813t-1.35.712m8.775-13.35q.2 2.75-.9 5.363T17.2 14.025l.5 2.475q.1.5-.05.975t-.5.825L14 21.45q-.375.375-.9.288t-.725-.588l-1.525-3.575L6.575 13.3L3 11.775q-.5-.2-.6-.725t.275-.9L5.825 7q.35-.35.837-.5t.988-.05l2.475.5q2.375-2.375 4.988-3.475t5.362-.9q.2.025.4.113t.35.237q.15.15.238.35t.112.4m-17.65 12.3q.875-.875 2.138-.887t2.137.862q.875.875.863 2.138t-.888 2.137q-1.2 1.2-2.838 1.425t-3.287.45l.45-3.287q.225-1.637 1.425-2.838m1.425 1.4q-.425.425-.587 1.025T4.5 19.625q.625-.1 1.225-.25T6.75 18.8q.3-.3.325-.725T6.8 17.35q-.3-.3-.725-.288t-.725.313" /></Icon>} >
        {t("app.send_leave_application")}</Button>
      <Button colorScheme='green' className='mt-6 w-full' onClick={()=>handleCancel()} isDisabled={!needCancel()}>{t("app.retrieve_application")}</Button>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isParentSignOpen}
        onClose={onParentSignClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className='!font-normal'>{t("app.parent_sing_info")}</ModalHeader>
          <ModalCloseButton />

          <ModalBody pb={6}>
            <div className='h-52 w-full border border-gray-200'>
              <SignatureCanvas penColor='blue' ref={sigCanvas}
                canvasProps={{ className: 'sigCanvas w-full h-52' }} />
            </div>
          </ModalBody>

          <ModalFooter className='gap-4'>
            <Button colorScheme='twitter' variant='outline' className="mr-auto w-full" onClick={clear}>{t("app.clear")}</Button>
            <Button colorScheme='twitter' className="w-full" onClick={() => { save(); onParentSignClose(); }}>{t("app.confirm")}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* 顯示載入中的視窗 */}
      <LoadingDialog isOpen={showLoading} />              
    </>
  );
}