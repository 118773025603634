import { AddIcon, ChevronDownIcon, EditIcon,SearchIcon } from "@chakra-ui/icons";
import { TableContainer, Table, Tr, Th, Tbody, Td, Text, Box, InputGroup, InputRightElement, Badge, Center, Button, InputLeftElement, Input, Checkbox, Stack, useColorModeValue, Switch, useDisclosure, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, InputLeftAddon,Select, Flex, Tag, TagLabel, TagCloseButton, effect, List, ListItem } from "@chakra-ui/react";
import { useEffect, useState, useMemo, useRef } from "react";
import { SchoolApprovalHelper } from "../../dal/school_approval_flows_helper";
import { DSAAbsenceHelper } from "../../dal/dsa_absence_helper";
import dayjs from "dayjs";
import { NotifyBoxModal } from "../../components/Modals/NotifyBoxModal";
import { useSchoolContext } from "../../dal/SchoolContext";
import { useUserContext } from "../../components/auth/user-validation";
import { set } from "date-fns";

interface StageData {
  id: string;
  mincount: number;
  maxcount: number;
  stage_no: number;
  stage_name: string;
  approvers_info: TeacherData[];
 // activate: boolean;
}

interface TeacherData {
  account: string;
  name: string;
}


/* 設定請假流程 */
export const ProcessPage = () => {
  const color = useColorModeValue('gray.100', 'gray.700');

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isNotifyOpen, onOpen: onNotifyOpen, onClose: onNotifyClose } = useDisclosure();
  const [teacherCbo, setTeacherCbo] = useState<TeacherData[]>([]); //審核帳號下拉選單
  const [allApprovalFlows, setAllApprovalFlows] = useState<any[]>([]);  //所有審核流程
  const [currentStage, setCurrentStage] = useState(0); //目前關卡
  const [approvalStage, setApprovalStage] = useState<StageData[]>([]); //審核關卡
  const [currentStageName, setCurrentStageName] = useState(''); //目前關卡名稱
  const [currentApprovers, setCurrentApprovers] = useState<TeacherData[]>([]); //目前關卡的審核帳號群
  const [selectedAccount, setSelectedAccount] = useState<TeacherData|null>(null);  //選擇的審核帳號
  const [notifyMessage, setNotifyMessage] = useState(''); //提示訊息內容
  const [isTeacherListOpen, setIsTeacherListOpen] = useState(false); //審核帳號下拉選單是否開啟
  const [searchTerm, setSearchTerm] = useState(''); //搜尋審核帳號之關鍵字串

  const [refresh, setRefresh] = useState(false); //重新載入

  const { userInfo } = useUserContext(); // 使用者資訊

  const { selectedSchool } = useSchoolContext(); // 學校資訊

  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  //設定審核帳號下拉選單(關鍵字篩選)
  const filteredTeachers = useMemo(() => {
    if (!searchTerm.trim()) {
      return teacherCbo;
    }
    return teacherCbo.filter((teacher) => {
      const searchString = `${teacher.name}-${teacher.account}`.toLowerCase();
      return searchString.includes(searchTerm.toLowerCase());
    });
  }, [teacherCbo, searchTerm]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setSelectedAccount(null);
    setIsTeacherListOpen(true);
  };

  const handleInputClick = () => {
    setIsTeacherListOpen(prev=>!prev);
  }

  const handleSelectAcount = (teacher:TeacherData) => {
    /*const accountData=e.target.value.split('-');
    setSelectedAccount({
      account:accountData[1],
      name:accountData[0]
    });*/
    setSelectedAccount(
    {
      account:teacher.account,
      name:teacher.name
    });
    setSearchTerm(`${teacher.name}-${teacher.account}`);
    setIsTeacherListOpen(false);
  }
  const handleAddAccount = () => () => {    
    if (selectedAccount) {
      const currStage=approvalStage[currentStage-1];
      if(currentApprovers.findIndex((ele)=>ele.account===selectedAccount.account)===-1){
        const newApprovers = [...currentApprovers, selectedAccount];
        setCurrentApprovers(newApprovers);
      }
      setSelectedAccount(null);
      setSearchTerm(''); 
    }
  }
  
  const handleOpenStage = (item:number) => () => {
    console.log('item',item)
    setCurrentStage(item);
    onOpen();
  }

  const handleDelAccount = (item:any) => () => {
    if (currentApprovers.length > 0) {
      const newApprovers = currentApprovers.filter((ele) => ele.account !== item.account);
      setCurrentApprovers(newApprovers);
    }
  }

  const handleStageName = (e:any) => {
    setCurrentStageName(e.target.value);
  }

  const loadCurrentStage=(stageNo:number)=>{
    const targetStageData=approvalStage[stageNo-1];
    setCurrentStageName(targetStageData.stage_name);
    setCurrentApprovers(targetStageData.approvers_info);
    setSelectedAccount(null);
  }

  const handleCount = (stageNo:number, minCount:string, maxCount:string) => {

    if((minCount==='') || (isNaN(Number(minCount)))) minCount='0';
    if((maxCount==='') || (isNaN(Number(maxCount)))) maxCount='0';
    const updatedApprovalStage = [...approvalStage];

    updatedApprovalStage[stageNo] = {
      ...updatedApprovalStage[stageNo],
      mincount: parseInt(minCount),
      maxcount: parseInt(maxCount),
  //    activate: (updatedApprovalStage[stageNo].stage_name!=='' && updatedApprovalStage[stageNo].approvers_info.length>0 && minCount!=='0' && maxCount!=='0')
    };
    setApprovalStage(updatedApprovalStage);
  }
  
  const handleSaveAccount = () => () => {        
    const updatedApprovalStage = [...approvalStage];
    if(currentApprovers.length>0 && currentStageName==='') 
    {
      setNotifyMessage('關卡名稱不可為空白');
      onNotifyOpen();
      return;
    }

    if(currentStageName!=='' && currentApprovers.length===0) 
    {
      setNotifyMessage('必須有審核帳號');
      onNotifyOpen();
      return;
    }    
    
    updatedApprovalStage[currentStage-1] = {
      ...updatedApprovalStage[currentStage-1],
      stage_name: currentStageName,
      approvers_info: currentApprovers,
//      activate: (currentStageName!=='' && currentApprovers.length>0 && updatedApprovalStage[currentStage-1].mincount>0 && updatedApprovalStage[currentStage-1].maxcount>0)
    };    
    setApprovalStage(updatedApprovalStage); 
    onClose();
  
  }

  const validateStageArray = (stages: StageData[]): string[] => {  
    const errMsg:string[]=[];
    for (let i = 1; i < stages.length; i++) {
      const CurrentStage = stages[i];
      const NextStage = (i===stages.length-1) ? stages[i]: stages[i + 1];

      if(CurrentStage.stage_name !=='' && CurrentStage.approvers_info.length>0 && (CurrentStage.maxcount===0 || CurrentStage.mincount===0)) errMsg.push(`關卡${i+1}-${CurrentStage.stage_name}起始日數或結束日數不可為空白`);
      if((CurrentStage.mincount>CurrentStage.maxcount) && CurrentStage.maxcount>0) errMsg.push(`關卡${i+1}-${CurrentStage.stage_name}起始日數${CurrentStage.mincount}不可大於結束日數${CurrentStage.maxcount}`);
      if(i!==stages.length-1) // 非最後一關
      {
        if(CurrentStage.stage_name==='' && NextStage.stage_name!=='') errMsg.push(`關卡${i+1}-${CurrentStage.stage_name}名稱不可為空白`);        
      }
    }
    return errMsg;
  }

  const handleSave = async () => {
    const validateResult = validateStageArray(approvalStage);
    if (validateResult.length > 0) {   
      setNotifyMessage(validateResult.join('\n'));
      onNotifyOpen();
      return;
    }
    else
    {
      await Promise.all (
        approvalStage.map(async (item,index) => {
          if (item.id==='') { // 新增關卡
            if(item.mincount!==0 && item.maxcount!==0 && item.stage_name!=='')
            {
              await SchoolApprovalHelper.addApprovalFlow(
                  item.stage_no,
                  item.stage_name,   
                  (index===0) ? 'HomeroomTeacher':'Officer' ,  // 審核類型(班導師與其他人不同)       
                  item.mincount,
                  item.maxcount,
                  JSON.stringify(item.approvers_info)
                );
            }
          } 
          else
          {
            if(item.stage_name!=='')   //關卡名稱不為空白時更新資料
            {
              await SchoolApprovalHelper.updateApprovalFlow(
                item.id.toString(),
                item.stage_no,
                item.stage_name,
                (index===0) ? 'HomeroomTeacher':'Officer' ,  // 審核類型(班導師與其他人不同)
                item.mincount,
                item.maxcount,
                JSON.stringify(item.approvers_info)
                );
            } else
            {
              if(item.id!=='')
              {
                await SchoolApprovalHelper.deleteApprovalFlow(item.id);
              }
            }       
          }
        })
      )    
      setNotifyMessage('儲存成功');
      onNotifyOpen();
      //loadData();   
      setRefresh(true);
    }
  }
  const loadData = async () => {
    const allTeacher = await DSAAbsenceHelper.getSchoolTeachers(selectedSchool?.dsns??'');
    if(allTeacher!==undefined)
    {
      const allTeacherData:TeacherData[] = allTeacher.teacher.filter((item:any) => item.teacherAcc !== null).map((item:any) => {
        return {
          account: item.teacherAcc,
          name: item.teacherName
        };
      });
      setTeacherCbo(allTeacherData);
    }
    else
    {
      setTeacherCbo([]);
    }    
    
    const allApprovalFlows = await SchoolApprovalHelper.getAllFlows(selectedSchool?.dsns??'');
    setAllApprovalFlows(allApprovalFlows)
    const appStages = [1, 2, 3, 4, 5, 6].map((item) => {
      const targetData = allApprovalFlows.find((ele) => ele.stage_no === item) || {};
      return {
        id: targetData.id || '',
        mincount: targetData.min_count || 0,
        maxcount: targetData.max_count || 0,
        stage_no: targetData.stage_no || item,
        stage_name: (item===1) ? '班導師' : targetData.stage_name || '',
        approvers_info: targetData.approvers_info || [],
      };
    });
    setApprovalStage(appStages);
  
  };

  useEffect(() => {
    if (refresh) {
      loadData();
      setRefresh(false);
    }
  }, [refresh]);
  
  useEffect(() => {
    loadData();
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node) &&
          listRef.current && !listRef.current.contains(event.target as Node)) {
        setIsTeacherListOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  },[])

  useEffect(() => {
    if(isOpen) loadCurrentStage(currentStage);
  },[isOpen])

  useEffect(() => {
    loadData();
  },[selectedSchool]);

  const checkPermission = () => {
    /* if(userInfo?.isSystemAdmin)
     {
       if((selectedSchool?.dsns===userInfo?.schoolDsns) && userInfo?.isAdmin)
       {
         return true;
       }
       else
       {
         return false;
       }
     }
     else
     {     
       return false;
     }*/
     if((selectedSchool?.dsns===userInfo?.schoolDsns) && userInfo?.isAdmin)
       {
         return true;
       }
       else
       {
         return false;
       }
 
   }
  return (
    <>
      <Box borderWidth='1px' borderRadius='lg' p="6" h='87vh' overflow='hidden'>
        <div className="h-full overflow-y-auto">
          {/* <div className="text-xl mb-4">一般請假</div> */}
          <div className="flex items-center text-xl mb-4">
            <div>選擇假別</div>
            <Select w='150px' size='lg' ms='4' className="!border-[#ffb0bf] hover:!border-[#ffb0bf]">
              <option>一般請假</option>
             {/* <option>公假請假</option> */}
            </Select>
          </div>
          <TableContainer>
            <Table size='lg'>
              <Tbody className="procTb">
                <Tr borderTopColor={color} className="border-t">
                  <Td style={{ padding: '4px' }}>關卡編號</Td>
                  <Td style={{ padding: '4px' }}>關卡名稱</Td>
                  <Td style={{ padding: '4px' }}>審核條件</Td>
                </Tr>                
                {
                  approvalStage.map((item,index)=>(
                    <>
                      <Tr key={index} >
                        <Td key={index} style={{ padding: '4px' }}>{index+1}</Td>
                        <Td style={{ padding: '4px' }} className="border-b">
                          {index === 0 ? (
                              <Text style={{ width: '100%', textAlign: 'center' }}>班導師</Text>
                            ) : (
                              <Button
                                rightIcon={<EditIcon color="gray.500" />}
                                className="border-b !rounded-none border-gray-500 w-[150px]"
                                variant="flushed"
                                onClick={handleOpenStage(index + 1)}
                                isDisabled={!checkPermission()}
                              >
                                <span className="mx-auto">{item.stage_name}</span>
                              </Button>
                            )} 
                        </Td>
                        <Td style={{ padding: '4px' }}>
                          連續<Input isDisabled={!checkPermission()} type="text" className="!border-x-0 !border-t-0 !rounded-none !border-[#ffb0bf] !w-[60px] text-center" value={(approvalStage[index]?.mincount===0)? '':approvalStage[index]?.mincount} onChange={(e)=>handleCount(index,e.target.value,approvalStage[index].maxcount.toString())}/>
                          日至<Input isDisabled={!checkPermission()} type="text" className="!border-x-0 !border-t-0 !rounded-none !border-[#ffb0bf] !w-[60px] text-center" value={(approvalStage[index]?.maxcount===0)? '':approvalStage[index]?.maxcount} onChange={(e)=>handleCount(index,approvalStage[index].mincount.toString(),e.target.value)}/>日

                        </Td> 
                      </Tr>                   
                    </>
                  ))
                }
              </Tbody>
            </Table>
          </TableContainer>
          <div className="flex justify-between items-center mt-6">
            <div>最近一次修改時間：{(allApprovalFlows.length===0) ? '' : dayjs(allApprovalFlows[0]?.last_update).format('YYYY/MM/DD HH:mm:ss')}</div>
            {checkPermission() &&
            <div className="flex ">
              <Button variant='outline' colorScheme='cyan' className="mr-2" onClick={()=>loadData()}>取消</Button>
              <Button variant='outline' colorScheme='cyan' onClick={()=>handleSave()}>儲存</Button>
            </div>
            }
          </div>
        </div>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>設定請假關卡</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <InputGroup>
                <InputLeftAddon>
                  關卡名稱
                </InputLeftAddon>
                <Input type='text' placeholder='' value={currentStageName} onChange={(e)=>handleStageName(e)}/>                
              </InputGroup>
              <Box position="relative" width="100%" mb={4}>             
              <Flex alignItems="center" position="relative">
                <Input
                  ref={inputRef}
                  placeholder="輸入教師姓名或帳號搜尋..."
                  value={searchTerm}
                  onChange={handleInputChange}
                  onClick={handleInputClick}
                  onFocus={() => setIsTeacherListOpen(true)}
                  pr="60px"
                  isDisabled={!checkPermission()}
                />
               {/*} <Select placeholder='選擇審核帳號' value={selectedAccount?.name+"-"+selectedAccount?.account} onChange={handleSelectAcount}>
                  {teacherCbo.length > 0 && teacherCbo.map((item) => (
                    <option key={item.teacherID} value={item.teacherName+"-"+item.teacherAcc}>
                      {item.teacherName}-{item.teacherAcc}
                    </option>
                  ))}
                </Select> */}
                <Box position="absolute" right="35px" pointerEvents="none">
                  <SearchIcon />
                </Box>
                <Box ml={2}>
                  <AddIcon color='green.500' onClick={handleAddAccount()}/>
                </Box>                
              </Flex>
              {
                isTeacherListOpen && (
                  <List
                  ref={listRef}
                  position="absolute"
                  top="100%"
                  left="0"
                  right="0"
                  maxH="250px"
                  overflowY="auto"
                  bg="white"
                  boxShadow="md"
                  borderRadius="md"
                  zIndex={1}
                >
                  {filteredTeachers.map((teacher, index) => (
                    <ListItem
                      key={index}                      
                      ml={3}                      
                      cursor="pointer"
                      _hover={{ bg: 'gray.100' }}
                      onClick={() => handleSelectAcount(teacher)}
                    >
                      <Text>{teacher.name}-{teacher.account}</Text>
                    </ListItem>
                  ))}
                </List>
                )
              }</Box>
              <Box>
                {
                  (currentStage !==1) &&
                  currentApprovers.map((item:any,index:number)=>(
                    <Tag key={index} size='md' colorScheme='cyan' mx={1}>
                      <TagLabel>{item.account}-{item.name}</TagLabel>
                      <TagCloseButton onClick={handleDelAccount(item)}/>
                    </Tag>
                  ))
                }
              </Box>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              取消
            </Button>
            <Button variant='outline' colorScheme='cyan' onClick={handleSaveAccount()} isDisabled={!((currentStageName==='' && currentApprovers.length===0) ||(currentStageName!=='' && currentApprovers.length>0))}>儲存</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <NotifyBoxModal isModalOpen={isNotifyOpen} title='資料輸入提示' message={notifyMessage} onModalClose={onNotifyClose}/>
    </>
  )
}